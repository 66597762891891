import Card from 'antd/es/card/Card';
import ReactApexChart from 'react-apexcharts';

const VulnBySeverity = ({ data = [] }: { data: any }) => <Card>
  <div>
    <h1>Vulnerabilities by Severity</h1>
    <ReactApexChart
      series={data}
      options={{
        chart: {
          type: 'bar',
          height: 300
        },
        colors: [
          '#FF9800', '#2196F3', '#4CAF50', '#E91E63', '#9E9E9E', '#6A0DAD'
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%'
            // endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Critical', 'High', 'Medium', 'Low', 'Information'],
          title: {
            text: 'Severity'
          }
        },
        yaxis: {
          title: {
            text: 'Number of Vulnerabilities'
          }
        },
        fill: {
          opacity: 60
        }
      }}
      type="bar"
      height={300}
    />
  </div>
</Card>;
export default VulnBySeverity;