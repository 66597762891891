import {
  Button,
  Flex,
  Radio,
  Select,
  Space,
  Tooltip,
  Form as AntdForm,
  Checkbox,
  GetProp,
  Collapse,
  CollapseProps,
  Divider,
  Input,
  Spin,
  Upload,
  Alert,
  Steps,
  message
} from 'antd';
import { BlobProvider, pdf } from '@react-pdf/renderer';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {
  useCallback,
  useEffect,
  useState
} from 'react';
import { VULNERABILITY_STATUS } from '../../../../config/enum';
import projectService from '../../../../service/project.service';
import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';
import { useDispatch, useSelector } from 'react-redux';
import { report } from '../../../../redux/report/actions';
import { reportItems } from '../../../../redux/report/selectors';
import CardZeus from '../../../UI/card/Card.ui';
import { debounce } from 'lodash';
import { FileSyncOutlined, PlusOutlined } from '@ant-design/icons';

const ReportCreateNew = () => {

  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);

  const handleUpdateReportDirect = (keyFiled: string, newname?: any) =>
    dispatch<any>(report.updateReportField(keyFiled, newname));

  const handleUpdateReport = useCallback(debounce((key, value) => {
    dispatch<any>(report.updateReportField(key, value));
  }, 0), []); // 500 ms delay

  const onChangeCheckFilter: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    handleUpdateReportDirect('filter', checkedValues);
  };
  const onChangeCheckTags: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    handleUpdateReportDirect('tags', checkedValues);
  };

  const [projects, setprojects] = useState([]);
  const [loadingPorject, setloadingPorject] = useState(false);
  useEffect(() => {
    const getProjects = async () => {
      setloadingPorject(true);
      const projects = await projectService.getListName();
      setprojects(projects.projects.map((v: any) => ({
        label: v.name + ` (${v.vulns_count})`,
        value: v.id
      })));
      setloadingPorject(false);
    };
    getProjects();
  }, []);
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Summary',
      children: <div>
        <Paragraph>Summary of methods and procedures used.</Paragraph>
        <TextArea
          rows={4}
          title='Summary'
          onChange={(e) => handleUpdateReport('summary', e.target.value)}
        />
      </div>
    }, {
      key: '2',
      label: 'Recommendations',
      children: <div>
        <Paragraph>Actions recommended to mitigate vulnerabilities found.</Paragraph>
        <TextArea
          rows={4}
          title='Recommendations'
          onChange={(e) => handleUpdateReport('recommendations', e.target.value)}
        />
      </div>
    }, {
      key: '3',
      label: 'Scope',
      children: <div>
        <Paragraph>Scope of analysis taken into consideration. It refers to the set of assets that were evaluated.</Paragraph>
        <TextArea
          rows={4}
          title='Scope'
          onChange={(e) => handleUpdateReport('scope', e.target.value)}
        />
      </div>
    }, {
      key: '4',
      label: 'Objectives',
      children: <div>
        <Paragraph>Objectives of the evaluation. Information expected to be obtained on the scope of analysis.</Paragraph>
        <TextArea
          rows={4}
          title='Objectives'
          onChange={(e) => handleUpdateReport('objectives', e.target.value)}
        />
      </div>
    }, {
      key: '5',
      label: 'Conclusions',
      children: <div>
        <Paragraph>Findings on vulnerabilities and their characteristics.</Paragraph>
        <TextArea
          rows={4}
          title='Conclusions'
          onChange={(e) => handleUpdateReport('conclusions', e.target.value)}
        />
      </div>
    }
  ];

  const stepsSteps = [
    {
      title: 'General Information',
      content: 'First-content'
    }, {
      title: 'Vulnerabilities',
      content: 'Second-content'
    }, {
      title: 'Sections',
      content: 'Last-content'
    }, {
      title: 'Summary',
      content: 'Last-content'
    }
  ];

  const [loadingPdfGeneration, setLoadingPdfGeneration] = useState(false);
  const [startGeneration, setStartGeneration] = useState(false);
  const getBase64FromBlobUrl = async (blobUrl: any) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const steps = [
    {
      title: 'First',
      content: 'First-content'
    }, {
      title: 'Second',
      content: 'Second-content'
    }, {
      title: 'Last',
      content: 'Last-content'
    }
  ];
  const [current, setCurrent] = useState(0);

  return <div>
    <Spin spinning={loadingPdfGeneration} fullscreen />
    <div>
      <div
        className="w-[100%] px-10 "
      >
        <div className="flex gap-10 gap-y-10 justify-center">
          <div className="w-full max-w-[800px]">
            <Steps
              current={current}
              items={stepsSteps}
            />
            <div className='mt-5'>
              <CardZeus className='!p-1'>
                <div
                  className="bg-white"
                >
                  <div
                    className={`w-full ${current === 0 ? 'block' : 'hidden'}`}
                  >
                    <Divider plain orientation='left'>
                      <Title
                        level={4}
                        className='text-primary'
                      >Generate Report</Title>
                    </Divider>
                    <AntdForm.Item
                      className='w-full'
                      layout="vertical"
                      required
                      label="Report Name"
                    >
                      <Input
                        title='name'
                        width={'100%'}
                        placeholder="Name"
                        aria-label='name'
                        onChange={(e) => handleUpdateReport('name', e.target.value)}
                      />
                    </AntdForm.Item>
                    <AntdForm.Item
                      className='w-full'
                      layout="vertical"
                      required
                      label="Client Name"
                    >

                      <Input
                        title='name'
                        width={'100%'}
                        placeholder="clientName"
                        aria-label='name'
                        onChange={(e) => handleUpdateReport('client_name', e.target.value)}
                      />
                    </AntdForm.Item>
                    <AntdForm.Item
                      className='w-full'
                      layout="vertical"
                      required
                      label="Project"
                    >

                      <Select
                        style={{ width: '100%' }}
                        placeholder="Please select projects..."
                        onChange={(value) => handleUpdateReport('project', value!)}
                        allowClear
                        value={reportData.project}
                        loading={loadingPorject}
                        disabled={loadingPorject}
                        options={projects}
                      />
                    </AntdForm.Item>
                    <Text strong>
                                            Add logo to report
                    </Text>
                    <Upload
                      listType="picture-card"
                      accept='image/*'
                      locale={{
                        previewFile: 'Preview'
                      }}
                      type='select'
                      maxCount={1}
                      beforeUpload={() => false}
                      onChange={async ({ fileList: newFileList }) => {
                        if (newFileList.length === 0) {
                          handleUpdateReport('image', null);
                          return;
                        }
                        const newPreviewUrl = URL.createObjectURL(newFileList[0].originFileObj!);
                        const base64String = await getBase64FromBlobUrl(newPreviewUrl);
                        handleUpdateReport('image', base64String);
                      }}
                    >
                      {
                        reportData.image ? null :
                          <button
                            style={{
                              border: 0,
                              background: 'none'
                            }}
                            type="button"
                          >
                            <PlusOutlined />
                            <div
                              style={{
                                marginTop: 8
                              }}
                            >Upload</div>
                          </button>

                      }
                    </Upload>
                  </div>

                  <div
                    className={`mt-10 ${current === 1 ? 'block' : 'hidden'}`}
                  >
                    <Divider plain orientation='left'>
                      <Title
                        level={4}
                        className='text-primary'
                      >Vulnerability List</Title>
                    </Divider>
                    <div className='text-gray-600'>
                                            Filtered list of detected vulnerabilities that will be shown in the report
                    </div>
                    <div>
                      <Title
                        level={5}
                      >What do you want to inculed?</Title>
                      <Radio.Group
                        onChange={(e) => handleUpdateReportDirect('vuln_filter', e.target.value)}
                        value={reportData.vuln_filter}
                        disabled={reportData.project ? false : true}
                      >
                        <Space
                          direction="vertical"
                        >
                          <Radio
                            value={1}
                          >All Vulnerabilities</Radio>
                          <Radio
                            value={2}
                          >Filter by fields</Radio>
                        </Space>
                      </Radio.Group>

                      <AntdForm
                        labelCol={{
                          span: 4
                        }}
                        wrapperCol={{
                          span: 14
                        }}
                        layout="horizontal"
                        disabled={reportData.vuln_filter !== 2}
                        style={{
                          maxWidth: 600
                        }}
                      >

                        <div
                          className='my-5 flex flex-col'
                        >
                                                    Filter by assets
                          <Select
                            allowClear
                            mode='multiple'
                            onChange={(v) => handleUpdateReport('asset', v)}
                            value={reportData.asset}
                            style={{
                              width: '100%'
                            }}
                            placeholder="Type and add asset..."
                            options={reportData.asset_list.map((v: any) => ({
                              label: v.ip,
                              value: v.ip
                            }))}
                          />
                        </div>

                        <div
                          className='text-gray-500 mt-10 mb-5'
                        >Click on the filter .</div>

                        <Checkbox.Group
                          options={options}
                          className='gap-4 flex'
                          onChange={onChangeCheckFilter}
                        />

                        <div
                          className='text-gray-600 mt-10 mb-5'
                        >Include this tags .</div>

                        <Checkbox.Group
                          options={optionTags}
                          className='gap-4 grid grid-cols-3'
                          onChange={onChangeCheckTags}
                        />
                      </AntdForm>
                    </div>
                  </div>

                  <div
                    className={`mt-10 ${current === 2 ? 'block' : 'hidden'}`}
                  >
                    <Divider plain orientation='left'>
                      <Title level={3}>
                                                Sections
                      </Title>
                    </Divider>

                    <Collapse
                      accordion
                      items={items}
                      size="small"
                    />
                  </div>

                </div>
              </CardZeus>
            </div>
            <div
              style={{ marginTop: 24 }}
              className='flex justify-end'
            >
              {current > 0 && (
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => setCurrent(current - 1)}
                >
                                    Previous
                </Button>
              )}
              {current < stepsSteps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => setCurrent(current + 1)}
                >
                                    Next
                </Button>
              )}
              {current === stepsSteps.length - 1 && (
                <Button
                  type="primary"
                  icon={<FileSyncOutlined />}
                  onClick={() => message.success('Processing complete!')}
                >
                    Generate Report
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div >
  </div >;
};

const options = [
  ...VULNERABILITY_STATUS, {
    label: 'confirmed',
    value: 'Confirmed'
  }, {
    label: 'unconfirmed',
    value: 'Unconfirmed'
  }, {
    label: 'Critical',
    value: 'Critical'
  }, {
    label: 'High',
    value: 'High'
  }, {
    label: 'Medium',
    value: 'Medium'
  }, {
    label: 'Low',
    value: 'Low'
  }, {
    label: 'Informational',
    value: 'Informational'
  }];

const optionTags = [{
  label: 'PATCH-AVAILABLE',
  value: 'PATCH-AVAILABLE'
}, {
  label: 'EXPLOIT-AVAILABLE',
  value: 'EXPLOIT-AVAILABLE'
}];
export default ReportCreateNew;
