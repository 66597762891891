import { Card } from 'antd';
import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useEffect } from 'react';

interface BarChartDoubleState {
  series: {
    name: string;

    data: number[];
  }[];
}

interface BAR_CHART_PROPS {
  seriesDefault?: number[];
  height?: number;
  title?: string;
  categories?: string[];
  series?: any[];
  extra?: React.ReactNode;
  distributed?: boolean;
  dataLabels?: boolean;
}

const BarChartDouble: React.FC<BAR_CHART_PROPS> = ({
  distributed = false,
  title = 'Most vulnerable assets',
  categories = [],
  dataLabels = false,
  series = [],
  extra

}) => {
  const [state, setState] = useState<BarChartDoubleState>({
    series
  });

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 300,
      stacked: true
    },
    colors: ['#7D3C98', '#E74C3C', '#F4D03F', '#27AE60', '#3498DB'],
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '55%',
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
        // endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['fff']
    },
    title: {
      text: 'Top 10 Assets by Total Vulnerabilities'
    },
    xaxis: {
      categories: categories
    },
    yaxis: {
    },
    fill: {
      opacity: 60
    }
  };

  return <div id="BarChartDouble" className="-ml-5">
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={350}
    />
  </div>;
};

export default BarChartDouble;
