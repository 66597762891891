import { lazy } from 'react';
import User from '../pages/user/User';
import CreateUserPAge from '../pages/user/create/CreateUser';
import Report from '../pages/report/Report';
import ReportCreatePage from '../pages/report/Create/ReportCreatePage';
import ReportCreatePageNew from '../pages/report/Create/ReportCreatePageNew';
import path from 'path';
import JiraConnect from '../components/features/connectors/jira';
const Project = lazy(() => import('../pages/project/Project'));
const Vulnerability = lazy(() => import('../pages/vulnerability/Vulnerability'));
const CVE = lazy(() => import('../pages/vulnerability/cve'));
const DetailVulnsPage = lazy(() => import('../pages/vulnerability/detail/DetailVulns'));
const Asset = lazy(() => import('../pages/asset/Asset'));
const CreatePage = lazy(() => import('../pages/asset/create/CreatePage'));
const AssetDetail = lazy(() => import('../pages/asset/detail/detail'));
const VulnerabilityCreate = lazy(() => import('../pages/vulnerability/create/CreateMnanuallyVulnPage'));
const DashboardDetailProject = lazy(() => import('../pages/project/details/dashboard/index'));
const ProjectCreate = lazy(() => import('../pages/project/create/CreateMnanuallyProjectPage'));
const VuldetailPage = lazy(() => import('../pages/vulnerability/details/VuldetailsPage'));
const ProjectdetailPage = lazy(() => import('../pages/project/details/ProjectDetailsPage'));
const ActionPlan = lazy(() => import('../pages/actionPlan/ActionPlan'));
const CreateActionPlanPage = lazy(() => import('../pages/actionPlan/create/CreateActionPlanPage'));
const DetailActionPlanPage = lazy(() => import('../pages/actionPlan/detail/DetailActoinPlanPage'));
const ProfilePage = lazy(() => import('../pages/profile'));
const SettingPage = lazy(() => import('../pages/settings'));
const ConnectorsPage = lazy(() => import('../pages/connectors'));
const AcunetixConnectorPage = lazy(() => import('../pages/connectors/acunetix/connector'));
const AcunetixDashboardPage = lazy(() => import('../pages/connectors/acunetix/dashboard'));
const JiraConnectPage = lazy(() => import('../pages/connectors/jira'));

const coreRoutes = [{
  path: '/vulnerability',
  title: 'Vulnerability',
  breadcrumb: 'Vulnerability',
  component: Vulnerability
}, {
  path: '/vulnerability/cve',
  title: 'CVE',
  breadcrumb: 'CVE',
  component: CVE
}, {
  path: '/project',
  title: 'Project',
  breadcrumb: 'Project',
  component: Project
}, {
  path: '/vulnerability/create',
  title: 'Vulnerability create',
  breadcrumb: 'Add manually',
  component: VulnerabilityCreate
}, {
  path: '/asset',
  title: 'Asset',
  breadcrumb: 'Asset',
  component: Asset
}, {
  path: '/asset/create',
  title: 'Asset create',
  breadcrumb: 'Create asset',
  component: CreatePage
}, {
  path: '/asset/:id',
  title: 'Asset Detail',
  breadcrumb: 'Asset Detail',
  component: AssetDetail
}, {
  path: '/vulnerability/:id',
  title: 'Vulnerability detail',
  breadcrumb: 'DetailVulnsPage',
  component: DetailVulnsPage
}, {
  path: '/project/create',
  title: 'Project create',
  breadcrumb: 'Add manually',
  component: ProjectCreate
}, {
  path: '/project/:id',
  title: 'Details',
  breadcrumb: 'Project Details',
  component: ProjectdetailPage
}, {
  path: '/project/:id/dashbord',
  title: 'Project details dashbord',
  breadcrumb: 'Dashbord',
  component: DashboardDetailProject
}, {
  path: '/user',
  title: 'User',
  breadcrumb: 'User',
  component: User
}, {
  path: '/user/create',
  title: 'create',
  breadcrumb: 'Add',
  component: CreateUserPAge
}, {
  path: '/report',
  title: 'Report',
  breadcrumb: 'Report',
  component: Report
}, {
  path: '/report/create',
  title: 'Report create',
  breadcrumb: 'Add',
  component: ReportCreatePage
}, {
  path: '/report/createNew',
  title: 'Report create',
  breadcrumb: 'Add',
  component: ReportCreatePageNew
}, {
  path: '/action-plan',
  title: 'Action plan',
  breadcrumb: 'Action plan',
  component: ActionPlan
}, {
  // create action plan
  path: '/action-plan/create',
  title: 'Action plan create',
  breadcrumb: 'Add',
  component: CreateActionPlanPage
}, {
  path: '/action-plan/:id',
  title: 'Details',
  breadcrumb: 'Action plan Details',
  component: DetailActionPlanPage
}, {
  path: '/profile',
  title: 'Profile',
  breadcrumb: 'Profile',
  component: ProfilePage
}, {
  path: '/settings',
  title: 'Settings',
  breadcrumb: 'Settings',
  component: SettingPage
}, {
  path: '/connectors',
  title: 'Connectors',
  breadcrumb: 'Connectors',
  component: ConnectorsPage
}, {
  path: '/connectors/jira',
  title: 'Jira Connector',
  breadcrumb: 'Jira Connector',
  component: JiraConnectPage
}, {
  path: '/connectors/acunetix',
  title: 'Acunetix Connector',
  breadcrumb: 'Acunetix Connector',
  component: AcunetixConnectorPage
}, {
  path: '/connectors/acunetix/dashboard',
  title: 'Acunetix Dashboard',
  breadcrumb: 'Acunetix Dashboard',
  component: AcunetixDashboardPage
}
];

const routes = [...coreRoutes];
export default routes;
